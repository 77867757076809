import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { Formik } from 'formik';
import axios from 'axios';

import {
  Button,
  Card,
  CardBody,
  FormMaskedInput,
  FormSelectField,
  FormTextField,
} from '_components/_core';
import { Col, Form } from 'react-bootstrap';

import { RequiredIndicator } from './styles';

const product = process.env.REACT_APP_CONTABILLS_APP;

const YES_NO_OPTIONS = [
  { value: 'Não', label: 'Não' },
  { value: 'Sim', label: 'Sim' },
];

const GENDER_OPTIONS = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' },
];

const CHURCH_OPTIONS = [
  { value: 'Batista Connect - Floramar', label: 'Batista Connect - Floramar' },
  { value: 'Batista Connect - Palmital', label: 'Batista Connect - Palmital' },
  { value: 'Batista Connect - São Benedito', label: 'Batista Connect - São Benedito' },
  { value: 'Batista Connect - Vespasiano', label: 'Batista Connect - Vespasiano' },
  { value: 'Batista Connect - Igarapé', label: 'Batista Connect - Igarapé' },
  {
    value: 'Batista Connect - Ribeirão das Neves',
    label: 'Batista Connect - Ribeirão das Neves',
  },
];

const AGE_OPTIONS = Array.from({ length: 100 }, (_, i) => i + 1).map(age => ({
  value: age,
  label: age,
}));

import ValidationSchema from './ValidationSchema';

function ConnectForm() {
  const [clickedDownload, setClickedDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendForm = async values => {
    try {
      setIsLoading(true);

      await axios.post(
        `https://creationsistemas.app.n8n.cloud/webhook/inscricao-encontro`,
        values,
      );

      setIsLoading(false);

      alert('Inscrição realizada com sucesso!');
    } catch (error) {
      setIsLoading(false);

      alert('Erro ao realizar inscrição!');
    }
  };

  const isDisabled = useCallback(
    values => {
      const needAuthorization = Number(values.idade) < 18;

      if (needAuthorization && !clickedDownload) {
        return true;
      }

      if (needAuthorization && clickedDownload) {
        return false;
      }

      return false;
    },
    [clickedDownload],
  );

  return (
    <Container>
      <Formik
        initialValues={{
          nome: '',
          sexo: '',
          idade: 0,
          igreja: '',
          lider_de_celula: '',
          endereco: '',
          telefone_whatsapp: '',
          telefone_residencial: '',
          telefone_comercial: '',
          conjuge: '',
          conjuge_nome: '',
          conjuge_telefone: '',
          saude: '',
          saude_detalhe: '',
          medicamento: '',
          medicamento_detalhe: '',
          parentes: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={handleSendForm}
      >
        {({ values, handleChange, handleSubmit }) => (
          <>
            <Card
              className="p-4 mt-3"
              style={{
                borderTop: '8px solid rgb(96, 125, 139)',
              }}
            >
              <h3>Ficha de Inscrição</h3>
              <p className="mb-0">
                <b>Encontro com Deus - 21 a 23 de Março</b>
              </p>
              <p className="mb-0 mt-2">
                <b>Sítio Kaxuá</b> <br />
                R. Dona Inházinha Castro, 1 Chácaras Del Rey <br />
                Santa Luzia, Minas Gerais
              </p>
            </Card>
            <Card className="mt-3">
              <CardBody>
                <h5>Ficha de Inscrição</h5>
                <Form.Row className="mt-3">
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      Nome completo
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormTextField name="nome" placeholder="Nome completo" />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <Form.Label>
                      Sexo
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormSelectField
                      name="sexo"
                      options={GENDER_OPTIONS}
                      isSearchable={false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <Form.Label>
                      Idade
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormSelectField name="idade" options={AGE_OPTIONS} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Igreja</Form.Label>
                    <FormSelectField
                      name="igreja"
                      options={CHURCH_OPTIONS}
                      isSearchable={false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Líder de célula</Form.Label>
                    <FormTextField name="lider_de_celula" placeholder="Líder de célula" />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md={4} className="mt-2">
                    <Form.Label>
                      Telefone WhatsApp com DDD
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormMaskedInput
                      name="telefone_whatsapp"
                      placeholder="Telefone WhatsApp"
                      type="PHONE"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mt-2">
                    <Form.Label>Telefone residencial</Form.Label>
                    <FormMaskedInput
                      name="telefone_residencial"
                      placeholder="Telefone residencial"
                      type="PHONE"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mt-2">
                    <Form.Label>Telefone comercial</Form.Label>
                    <FormMaskedInput
                      name="telefone_comercial"
                      placeholder="Telefone comercial"
                      type="PHONE"
                    />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md={12} className="mt-2">
                    <Form.Label>
                      Endereço
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormTextField name="endereco" placeholder="Endereço" />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md={4} className="mt-2">
                    <Form.Label>
                      Possui cônjuge?
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormSelectField
                      name="conjuge"
                      options={YES_NO_OPTIONS}
                      isSearchable={false}
                    />
                  </Form.Group>
                  {values.conjuge === 'Sim' && (
                    <>
                      <Form.Group as={Col} md={4} className="mt-2">
                        <Form.Label>
                          Nome cônjuge
                          {values.conjuge === 'Sim' && (
                            <RequiredIndicator>*</RequiredIndicator>
                          )}
                        </Form.Label>
                        <FormTextField
                          name="conjuge_nome"
                          placeholder="Nome cônjuge"
                          disabled={values.conjuge !== 'Sim'}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4} className="mt-2">
                        <Form.Label>
                          Telefone cônjuge
                          {values.conjuge === 'Sim' && (
                            <RequiredIndicator>*</RequiredIndicator>
                          )}
                        </Form.Label>
                        <FormMaskedInput
                          name="conjuge_telefone"
                          placeholder="Telefone cônjuge"
                          disabled={values.conjuge !== 'Sim'}
                          type="PHONE"
                        />
                      </Form.Group>
                    </>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>
                      Sofre algum distúrbio de saúde?
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormSelectField
                      name="saude"
                      options={YES_NO_OPTIONS}
                      isSearchable={false}
                    />
                  </Form.Group>
                  {values.saude === 'Sim' && (
                    <Form.Group as={Col} md={8}>
                      <Form.Label>Se sim, qual?</Form.Label>
                      <FormTextField
                        name="saude_detalhe"
                        placeholder="Detalhes sobre o distúrbio de saúde"
                        disabled={values.saude !== 'Sim'}
                      />
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>
                      Faz uso de algum medicamento?
                      <RequiredIndicator>*</RequiredIndicator>
                    </Form.Label>
                    <FormSelectField
                      name="medicamento"
                      options={YES_NO_OPTIONS}
                      isSearchable={false}
                    />
                  </Form.Group>
                  {values.medicamento === 'Sim' && (
                    <Form.Group as={Col} md={8}>
                      <Form.Label>Se sim, qual?</Form.Label>
                      <FormTextField
                        name="medicamento_detalhe"
                        placeholder="Nome do medicamento"
                        disabled={values.medicamento !== 'Sim'}
                      />
                    </Form.Group>
                  )}
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} md={12} className="mt-2">
                    <Form.Label>Parentes ou amigos próximos (Nome e telefone)</Form.Label>
                    <FormTextField
                      name="parentes"
                      placeholder="Nome e telefone de parentes ou amigos próximos"
                    />
                  </Form.Group>
                </Form.Row>
              </CardBody>
            </Card>
            {Number(values.idade) < 18 && (
              <Card
                className="p-4 mt-3"
                style={{
                  borderTop: '8px solid rgb(194, 175, 6)',
                }}
              >
                <h4>Autorização para menores de 18 anos</h4>
                <p className="mb-0 mt-2">
                  1. Faça o download do arquivo abaixo e peça para o responsável preencher
                  e assinar. <br />
                  2. Assim que tiver preenchido e assinado, tire uma foto e nos envie para
                  nosso WhatsApp da inscrição. <br />
                </p>
                <p className="mt-3 mb-0">
                  <b>
                    <a
                      onClick={() => setClickedDownload(true)}
                      href="https://contabil-easy.s3.sa-east-1.amazonaws.com/debug/Autoriza%C3%A7%C3%A3o+Encontro.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Baixar arquivo autorização
                    </a>
                  </b>
                </p>
              </Card>
            )}
            <div className="mt-2">&nbsp;</div>

            <div className="d-flex justify-content-start align-items-center">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isDisabled(values)}
                isLoading={isLoading}
              >
                Enviar inscrição
              </Button>
              {isDisabled(values) && (
                <small className="ml-3 text-yellow">
                  Baixe o arquivo de autorização antes de enviar sua inscrição
                </small>
              )}
            </div>
            <div className="mt-5">&nbsp;</div>
          </>
        )}
      </Formik>
    </Container>
  );
}

ConnectForm.defaultProps = {
  segments: [],
};

ConnectForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onFetchPlans: PropTypes.func.isRequired,
  onEmailConfirmation: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default ConnectForm;
