import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const ValidationSchema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório').max(255).nullable(true),
  sexo: Yup.string()
    .oneOf(['Masculino', 'Feminino'], 'Campo obrigatório')
    .required('Campo obrigatório')
    .nullable(true),
  idade: Yup.number().required('Campo obrigatório').nullable(true),
  igreja: Yup.string().nullable(true),
  lider_de_celula: Yup.string().nullable(true),
  endereco: Yup.string().required('Campo obrigatório').nullable(true),
  telefone_whatsapp: Yup.string().required('Campo obrigatório').nullable(true),
  telefone_residencial: Yup.string().nullable(true),
  telefone_comercial: Yup.string().nullable(true),
  conjuge: Yup.string()
    .oneOf(['Sim', 'Não'], 'Campo obrigatório')
    .required('Campo obrigatório')
    .nullable(true),
  conjuge_nome: Yup.string().when('conjuge', {
    is: 'Sim',
    then: Yup.string().required('Campo obrigatório').nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  conjuge_telefone: Yup.string().when('conjuge', {
    is: 'Sim',
    then: Yup.string().required('Campo obrigatório').nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  saude: Yup.string()
    .oneOf(['Sim', 'Não'], 'Campo obrigatório')
    .required('Campo obrigatório')
    .nullable(true),
  saude_detalhe: Yup.string().when('saude', {
    is: 'Sim',
    then: Yup.string().required('Campo obrigatório').nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  medicamento: Yup.string()
    .oneOf(['Sim', 'Não'], 'Campo obrigatório')
    .required('Campo obrigatório')
    .nullable(true),
  medicamento_detalhe: Yup.string().when('medicamento', {
    is: 'Sim',
    then: Yup.string().required('Campo obrigatório').nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  parentes: Yup.string().nullable(true),
});

export default ValidationSchema;
